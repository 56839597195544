// contexts/CookieConsentContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import CookieUtils from '../../libraries/cookies';

const COOKIES_NAME = 'hol_CookiePreferences';

const defaultState = {
  necessary: true,
  analytics: true,
  advertising: true,
};

const CookieConsentContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const CookieConsentProvider = ({ children }) => {
  const { setCookie, getCookie } = CookieUtils;
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [acceptPreference, setAcceptPreference] = useState(undefined);
  const [cookiesPreference, setCookiesPreference] = useState(defaultState);

  useEffect(() => {
    const existingPreferences = getCookie(COOKIES_NAME);
    if (existingPreferences) {
      setCookiesPreference(JSON.parse(existingPreferences));
    }
  }, []);

  const acceptAll = () => {
    const preferences = { necessary: true, analytics: true, advertising: true };
    setCookiesPreference(preferences);
    setCookie(COOKIES_NAME, JSON.stringify(preferences), {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days
      domain: '.hukumonline.com',
      path: '/',
    });

    setIsSettingsOpen(false);
    setIsBannerOpen(false);
  };

  const rejectAll = () => {
    const preferences = {
      necessary: true,
      analytics: false,
      advertising: false,
    };
    setCookiesPreference(preferences);
    setCookie(COOKIES_NAME, JSON.stringify(preferences), {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days
      domain: '.hukumonline.com',
      path: '/',
    });

    setIsSettingsOpen(false);
    setIsBannerOpen(false);
  };

  const updatePreference = (value, checked) => {
    setCookiesPreference((prev) => ({ ...prev, [value]: checked }));
  };

  const savePreferences = () => {
    setCookie(COOKIES_NAME, JSON.stringify(cookiesPreference), {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days
      domain: '.hukumonline.com',
      path: '/',
    });

    setIsSettingsOpen(false);
    setIsBannerOpen(false);
  };

  const toggleSettings = (isOpen) => {
    setIsSettingsOpen(isOpen);

    if (isOpen) {
      setIsBannerOpen(false);
    } else if (acceptPreference) {
      setIsBannerOpen(false);
    } else {
      setIsBannerOpen(true);
    }
  };

  const toggleBanner = (isOpen) => {
    setIsBannerOpen(isOpen);
  };

  const updateAcceptPreference = (data) => {
    setAcceptPreference(data);
  };

  useEffect(() => {
    const existingPreferences = getCookie(COOKIES_NAME);

    if (existingPreferences) {
      setCookiesPreference(JSON.parse(existingPreferences));
      setAcceptPreference(JSON.parse(existingPreferences));
      setIsBannerOpen(false);
      return;
    }

    setIsBannerOpen(true);
  }, []);

  return (
    <CookieConsentContext.Provider
      value={{
        cookiesPreference,
        isSettingsOpen,
        isBannerOpen,
        acceptPreference,
        acceptAll,
        rejectAll,
        updatePreference,
        savePreferences,
        toggleSettings,
        toggleBanner,
        updateAcceptPreference,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error(
      'useCookieConsent must be used within a CookieConsentProvider',
    );
  }
  return context;
};
