import React from 'react';

import {
  PiCookieBold,
  PiMinusCircleBold,
  PiPlusCircleBold,
  PiXBold,
} from 'react-icons/pi';

import { Container, Card, CardBody, Button, Input } from 'reactstrap';

import { isMobile } from 'react-device-detect';
import { useCookieConsent } from './CookiesConsent.context';

import stylesheet from './styles.scss';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { PRO_URL } = publicRuntimeConfig;

export const AccordionWithCheckbox = (props) => {
  const {
    isCheckbox,
    title,
    isChecked,
    valueChecked,
    isRequired,
    onChangeData,
    children,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    onChangeData(value, checked);
  };

  return (
    <div className="accordions_cookies__wrapper">
      <header className="accordions_cookies__header">
        {isCheckbox && (
          <div style={{ flex: '1 1 0%' }}>
            <Input
              value={valueChecked}
              checked={isChecked}
              type="checkbox"
              onChange={handleCheckboxChange}
              className="position-relative m-0"
              disabled={isRequired}
            />
          </div>
        )}

        <div
          onClick={handleToggle}
          className="accordions_cookies__header__title"
        >
          <h2>{title}</h2>
          {isOpen ? <PiMinusCircleBold /> : <PiPlusCircleBold />}
        </div>
      </header>
      {isOpen && <section className="px-4 py-2">{children}</section>}
    </div>
  );
};

export const CookiesConsent = (props) => {
  const { t, route } = props;

  const {
    cookiesPreference,
    isSettingsOpen,
    isBannerOpen,
    acceptAll,
    rejectAll,
    updatePreference,
    savePreferences,
    toggleSettings,
  } = useCookieConsent();
  const isCookiesPage = route.includes('/cookies');
  const dataPreference = [
    {
      title: t('cookies-settings-opt-1-title'),
      desc: t('cookies-settings-opt-1-desc'),
      value: 'necessary',
      isRequered: true,
    },
    {
      title: t('cookies-settings-opt-2-title'),
      desc: t('cookies-settings-opt-2-desc'),
      value: 'analytics',
      isRequered: false,
    },
    {
      title: t('cookies-settings-opt-3-title'),
      desc: t('cookies-settings-opt-3-desc'),
      value: 'advertising',
      isRequered: false,
    },
  ];

  const handleCheckboxChange = (value, checked) => {
    updatePreference(value, checked);
  };

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />

      {isBannerOpen && (
        <div className="banner_cookies">
          <Container>
            <Card>
              <CardBody>
                <h2 className="mb-1 banner_cookies__title">
                  <PiCookieBold className="d-block d-md-inline-block mr-1" />
                  {t('cookies-banner-title')}
                </h2>
                <p className="banner_cookies__desc">
                  {t('cookies-banner-desc')}{' '}
                  <a
                    href={`${PRO_URL}/cookies/`}
                    target="_blank"
                    rel="noreferrer"
                    className="__detail_cookies_banner"
                  >
                    <strong>{t('cookies-banner-desc-cta')}</strong>{' '}
                  </a>
                  {t('cookies-banner-desc-ext')}
                </p>

                <div className="banner_cookies__footer">
                  <div className="action">
                    <Button
                      type="button"
                      onClick={() => toggleSettings(true)}
                      outline
                      title="Cookies Settings"
                      name="cookies_settings"
                      color="primary"
                      block={isMobile}
                      size={isMobile ? 'sm' : ''}
                      className="__cookies_settings_banner"
                    >
                      {t('cookies-label-personalize')}
                    </Button>
                  </div>
                  <div className="action">
                    <Button
                      type="button"
                      title="Cookies Accept"
                      name="cookies_accept"
                      color="primary"
                      block={isMobile}
                      onClick={acceptAll}
                      size={isMobile ? 'sm' : ''}
                      className="__accept_all_banner"
                    >
                      {t('cookies-label-accept-all')}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}

      {isSettingsOpen && (
        <div className="modal_settings_cookies">
          <Card className="modal_settings_cookies__card p-4">
            <div className="modal_settings_cookies__close">
              <button
                type="button"
                name="close"
                onClick={() => toggleSettings(false)}
              >
                <PiXBold />
              </button>
            </div>

            <CardBody className="p-0">
              <h2 className="mb-1 modal_settings_cookies__title">
                <PiCookieBold className="d-block d-md-inline-block mr-1" />
                {t('cookies-settings-title')}
              </h2>
              <p className="modal_settings_cookies__desc">
                {t('cookies-settings-desc')}
              </p>

              <div className="accordions_cookies">
                {dataPreference.map((item, idx) => (
                  <AccordionWithCheckbox
                    isCheckbox
                    title={item.title}
                    onChangeData={
                      item.isRequered ? undefined : handleCheckboxChange
                    }
                    isChecked={
                      item.isRequered
                        ? item.isRequered
                        : cookiesPreference[item.value]
                    }
                    isRequired={item.isRequered}
                    valueChecked={item.value}
                    key={`option-${idx}`}
                  >
                    {item.desc}
                  </AccordionWithCheckbox>
                ))}
              </div>

              <p className="modal_settings_cookies__desc">
                {t('cookies-settings-closing')}
              </p>

              <div className="modal_settings_cookies__footer pb-4">
                <div
                  className={`left-action ${
                    isCookiesPage
                      ? 'justify-content-end d-md-flex d-block '
                      : ''
                  }
                     ${isMobile ? '' : ''} `}
                >
                  {isCookiesPage ? null : (
                    <a
                      href={`${PRO_URL}/cookies/`}
                      target="_blank"
                      rel="noreferrer"
                      className="__detail_cookies_personalize"
                    >
                      <Button
                        type="button"
                        color="primary"
                        outline
                        title="Cookies Settings"
                        name="cookies_settings"
                        block={isMobile}
                        size={isMobile ? 'sm' : ''}
                        className="__detail_cookies_personalize"
                      >
                        {t('cookies-label-cookies-details')}
                      </Button>
                    </a>
                  )}

                  <div className="">
                    <Button
                      title="Cookies Reject"
                      name="cookies_reject"
                      type="button"
                      color="primary"
                      outline
                      block={isMobile}
                      size={isMobile ? 'sm' : ''}
                      onClick={rejectAll}
                      className="__reject_all_personalize"
                    >
                      {t('cookies-label-reject-all')}
                    </Button>
                  </div>
                </div>
                <div className="right-action">
                  <Button
                    title="Cookies Accept"
                    name="cookies_accept"
                    type="button"
                    color="primary"
                    block={isMobile}
                    size={isMobile ? 'sm' : ''}
                    className="__accept_all_personalize"
                    onClick={savePreferences}
                  >
                    {t('cookies-label-save-preference')}
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};

export default CookiesConsent;
