/**
 * Utility functions for handling cookies in JavaScript.
 */
const CookieUtils = {
  /**
   * Gets the value of a cookie by its name.
   *
   * @param name - The name of the cookie to retrieve.
   * @returns The value of the cookie if found, or `null` if not found.
   */
  getCookie: function(name) {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${encodeURIComponent(name)}=([^;]*)`),
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  },

  /**
   * Sets a cookie with a given name, value, and optional options.
   *
   * @param name - The name of the cookie to set.
   * @param value - The value of the cookie.
   * @param options - Additional options for the cookie, such as `expires`, `path`, or `secure`.
   */
  setCookie: function(name, value, options = {}) {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
      value,
    )}`;

    if (options.expires) {
      if (options.expires instanceof Date) {
        cookieString += `; expires=${options.expires.toUTCString()}`;
      } else if (typeof options.expires === 'number') {
        const date = new Date();
        date.setTime(date.getTime() + options.expires * 1000);
        cookieString += `; expires=${date.toUTCString()}`;
      } else {
        cookieString += `; expires=${options.expires}`;
      }
    }

    if (options.path) {
      cookieString += `; path=${options.path}`;
    }

    if (options.domain) {
      cookieString += `; domain=${options.domain}`;
    }

    if (options.secure) {
      cookieString += `; secure`;
    }

    if (options.sameSite) {
      cookieString += `; samesite=${options.sameSite}`;
    }

    document.cookie = cookieString;
  },

  /**
   * Deletes a cookie by its name.
   *
   * @param name - The name of the cookie to delete.
   * @param path - The path where the cookie is valid (default is `/`).
   */
  deleteCookie: function(name, path = '/') {
    document.cookie = `${encodeURIComponent(
      name,
    )}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  },
};

export default CookieUtils;
